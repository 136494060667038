<template>
  <div class="guarantees">
    <div class="fluid container">
      <div class="topTittle">
        <h2>中国知链的多重保障</h2>
        <!-- <p>Multiple guarantees</p> -->
      </div>
      <a-row class="guaranteesRow">
        <a-col
        class="guaranteesCol"
          :xs="12"
          :md="12"
          :lg="12"
          :xl="12"
          v-for="(item,index) in list"
          :key="index"
        >
          <div class="block"
           :ref="refName+index"
          :class="{'animate__animated':refArray[refName+index],'animate__fadeInDown':refArray[refName+index]}">
            <div class="topWrap">
              <img :src="item.imgSrc">
              <div>
                 <h3>{{item.tittle}}</h3>
                 <p>{{item.desc}}</p>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { scrollAnimate } from '../mixins'
export default {
  name: 'GuaranteesBlock',
   mixins: [scrollAnimate],
  data () {
    return {
      list:[
        {
          imgSrc:'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/home-img14.png',
          tittle:'核心技术',
          desc:'原创作品存证保护，一经写入，不可篡改，完成版权溯源'
        },
        {
          imgSrc:'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/home-img15.png',
          tittle:'实时监控',
          desc:'大数据监控、智能合约约束，完成存取、溯源、利益分成闭环'
        },
        {
          imgSrc:'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/home-img16.png',
          tittle:'智能取证',
          desc:'全网AI智能检索监控存证，去中心化内容溯源和筛选机制实现内容的存储取证'
        },
        {
          imgSrc:'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/home-img17.png',
          tittle:'法律维护',
          desc:'最高人民法院中国行为法学会合作，最高人民法院向全国推广'
        },
      ],
      refName:'GuaranteesBlock'
    }
  },

  methods: {
 
  }
}
</script>

<style lang="scss">

.guarantees {
  background: #fff;
  background-size: 100% auto;
  padding: 72px 0;
  .topTittle {
    text-align: center;
    font-size: 20px;
    margin-bottom: 30px;
    h2 {
      font-size: 32px;
      margin-bottom: 4px;
      position: relative;
    }
    h2::after{
      content: '';
      width: 40px;
      height: 6px;
      background: #3377FE;
      position: absolute;
      left: 50%;
      margin-left: -20px;
      bottom: -30px;
    }
  }
  .guaranteesRow{
    padding-top: 20px;
    .ant-col{
      overflow: hidden;
      padding: 40px 20px;
    }
    .block {
      .topWrap{
        display: flex;
        // align-items: center;
        justify-content: center;
        background-size: 100% auto;
        img{
          width:120px;
          height: 120px;
          display: inline-block;
          margin-right: 20px;
        }
        h3 {
          margin-bottom: 10px;
          font-size: 20px;
          color:#112435;
        }
        p{
          font-size: 16px;
          color: #495C6F;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px){
  .guarantees {
      .ant-col{width: 50%;}
  }
}

@media screen and (min-width: 1px) and (max-width: 767px) {
  .guarantees {
    padding: 36px 0;
    .guaranteesRow {
      .guaranteesCol{
        padding: 20px;
        .block {
          .topWrap{
            display: block;
            text-align: center;
          }
        }
      }
    }
  }
}


</style>

<template>
  <div class="record">
    <div class="fluid container">
      <div class="toptitle">
        <h2>实时数据</h2>
        <!-- <p>Record</p> -->
      </div>
      <p class="recprd_tips">中国知链学术论文知识产权保护及认证可信体系通过区块链技术，基于IPFS协议，token机制，区块链全网AI智能检索监控存证，AI知识图谱搜索引擎，去中心化内容溯源和筛选机制实现内容的存储、检索、版权保护和知识传播。</p>
      <a-row class="recordRow">
        <a-col
        class="recordCol"
          :xs='8'
          :md="8"
          :lg="8"
          :xl="8"
          v-for="(item,index) in list"
          :key="index"
        >
          <router-link to="/Record" class="block"
           :ref="refName+index"
           :message="list"
          :class="{'animate__animated':refArray[refName+index],'animate__fadeInDown':refArray[refName+index]}">
            <div class="topWrap">
              <div>
                 <ICountUp
                    :delay="delay"
                    :endVal="item.title"
                  />
                 <p>{{item.desc}}</p>
              </div>
            </div>
          </router-link>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { scrollAnimate } from '../mixins'
import { queryExistEvidenceSummary,queryExistEvidenceSummary2 } from "@/api/EvidenceService"
import ICountUp from 'vue-countup-v2'
export default {
  name: 'RecordBlock',
  components: {
    ICountUp
  },
  mixins: [scrollAnimate],
  data () {
    return {
      list:[
        {
          title: 0,
          desc:'中国知链总存证书(份)',
          // sign: '+'
        },
        {
          title: 0,
          desc:'今日新增存证数(份)',
          // sign: '+'
        },
        {
          title: 0,
          desc:'交易金额总数(元)',
          sign: '元'
        },
      ],
      delay: 1000,
      refName:'RecordBlock',
      orderFilter:{
         status:null,
      },
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      console.log("init result ..");
//       queryExistEvidenceSummary(this.orderFilter).then(({data})=>{
// //         console.log(data)
//         let list = this.list;
//         list[0].title=parseInt(data.totalCount);
//         let todayCount=parseInt(data.todayCount);
//         if(todayCount==0){
//           todayCount = 18;
//         }
//         list[1].title=todayCount
// //         list[2].title=data.totalAmount;
//         list[2].title=parseInt(data.totalCount * 188);
//       })

      queryExistEvidenceSummary2().then(({data})=>{
//         console.log('5555',data)
        this.list[0].title=parseInt(data.totalCount);
        this.list[1].title=parseInt(data.todayCount);
        this.list[2].title=parseInt(data.totalAmount);
      })
    },


 
  }
}
</script>

<style lang="scss">
.record {
  background: #1E66FE;
  height: 400px;
  margin-bottom: 100px;
  padding: 72px 0;
  .toptitle {
    text-align: center;
    font-size: 20px;
    margin-bottom: 24px;
    h2 {
      font-size: 32px;
      margin-bottom: 30px;
      color: #fff;
      position: relative;
    }
    h2::after{
      content: '';
      width: 40px;
      height: 6px;
      background: #fff;
      position: absolute;
      left: 50%;
      margin-left: -20px;
      bottom: -30px;
    }
    p{
      color: #fff;
    }
  }
  .recprd_tips{
    font-size: 16px;
    color: #fff;
    text-align: center;
    line-height: 30px;
    padding: 20px 0;
  }
  .recordRow{
    background: #fff;
      border-radius: 12px;
      box-shadow: 0px 0px 20px 0px rgba(30,102,254,0.1);
      overflow: hidden;
    .recordCol{
      padding: 0;
      height: 250px;
      .block{
        display: block;
        width: 100%;
        height: 100%;
        text-decoration: none;
        text-align: center;
        padding: 50px 0;
        position: relative;
        span{font-size: 54px;color: #2D70FF;line-height: 90px;border-bottom: none;}
        p{font-size: 14px;color: #001E42;line-height: 40px;}
      }
      .block:hover{
        background: #F2F6FF;
      }
      .block::after{
        content: '';
        width: 2px;
        height: 90px;
        background: #C6D1DF;
        position: absolute;
        top: 80px;
        right: -1px;
      }
    }
    .recordCol:last-child .block::after{
      display: none;
    }
  }
  .ant-col{
    overflow: hidden;
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px){
  .record {
    margin-bottom: 400px;
    .ant-col{width: 50%;}
  }
}
@media screen and (min-width: 1px) and (max-width: 767px) {
  .record{
    padding: 36px 0;
    position: relative;
    margin-bottom: 50px;
    .recordRow{
      margin-top: 20px;
      .recordCol{
        height: 120px!important;
        .block{
          padding: 10px 0!important;
          span{
            font-size: 20px!important;
            line-height: 40px!important;
          }
          p{
            padding: 0 20px;
            font-size: 12px!important;
            line-height: 30px!important;
          }
        }
        .block::after{
          top: 20px!important;
        }
      }
    }
    
  }
}


</style>

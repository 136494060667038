<template>
  <div class="superiority">
    <div class="fluid container">
      <div class="topTittle">
        <h2>中国知链的优势</h2>
        <!-- <p>Superiority</p>s -->
      </div>
      <a-row class="superiorityRow">
        <a-col
        class="superiorityCol"
          :md="24"
          :lg="12"
          :xl="12"
        >
          <img src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/home-img01.png" alt="">
        </a-col>
        <a-col
        class="superiorityCol"
          :md="24"
          :lg="12"
          :xl="12"
        >
          <div class="title">
            <h2>性能优越的可信数据存证方案</h2>
            <p>中国知链是一条自主研发的区块链架构，针对个性化的使用场景全面优化后的高性能区块链。</p>
          </div>
          <div class="block" 
          v-for="(item,index) in list"
          :key="index"
          :ref="refName+index"
          :class="{'animate__animated':refArray[refName+index],'animate__fadeInDown':refArray[refName+index]}">
            <div>
              <h3>{{item.tittle}}</h3>
              <p>{{item.desc}}</p>
            </div>
          </div>
        </a-col>
        
        <a-col
        class="superiorityCol"
          :md="24"
          :lg="12"
          :xl="12"
        >
        <div class="title">
            <h2>全网AI智能检索监控存证</h2>
            <p>区块链全网AI知识图谱搜索引擎，去中心化内容溯源和筛选实现内容的存储、检索、版权保护和知识传播</p>
          </div>
          <div class="block"
           v-for="(item,index) in list2"
          :key="index"
          :ref="refName+index"
          :class="{'animate__animated':refArray[refName+index],'animate__fadeInDown':refArray[refName+index]}">
            <div>
              <h3>{{item.tittle}}</h3>
              <p>{{item.desc}}</p>
            </div>
          </div>
        </a-col>
        <a-col
        class="superiorityCol"
          :md="24"
          :lg="12"
          :xl="12"
        >
          <img src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/home-img02.png" alt="">
        </a-col>
      
      </a-row>
    </div>
  </div>
</template>

<script>
import { scrollAnimate } from '../mixins'
export default {
  name: 'SuperiorityBlock',
  mixins: [scrollAnimate],
  data () {
    return {
       list:[
        {
          tittle:'上链确权存证',
          desc:'采用区块链技术，提供原创作品存证保护服务'
        },
        {
          tittle:'全网侵权检测',
          desc:'依托大数据区块链文字监测技术，精准定位侵权线索'
        },
        {
          tittle:'作品侵权可查',
          desc:'原创作品可实时溯源查证'
        },
      ],
      list2:[
        {
          tittle:'在线智能签约',
          desc:'提供智能合约，快速签署，利益分成'
        },
        {
          tittle:'权威部门合作',
          desc:'最高人民法院中国行为法学会合作'
        },
        {
          tittle:'专业团队协助',
          desc:'专业技术团队与执行团队协作，高效存取、合理维权'
        },
      ],
         refName:'SuperiorityBlock'
    }
  },

  methods: {}
}
</script>

<style lang='scss'>

.superiority {
  padding: 72px 0;
  .topTittle {
    text-align: center;
    font-size: 20px;
    margin-bottom: 30px;
    color:#fff;
    h2 {
      font-size: 32px;
      margin-bottom: 4px;
      position: relative;
    }
    h2::after{
      content: '';
      width: 40px;
      height: 6px;
      background: #3377FE;
      position: absolute;
      left: 50%;
      margin-left: -20px;
      bottom: -30px;
    }
  }
  
  
  .superiorityRow{
    width: 100%;
    .superiorityCol{
      margin-top: 20px;
      height: 400px;
      img{height: 100%;}
      .title{
        h2{font-size: 24px;color: #001216;line-height: 50px;}
        p{font-size: 14px;color: #445D71;line-height: 30px;}
      }
      .block {
        background: 0 16px no-repeat;
        background-size: 48px;
        padding: 10px 58px;
        height: 80px;
        h3 {
          font-size: 20px;
          line-height:40px;
        }
        p {
          font-size: 14px;
          line-height: 20px;
          color: #495C6F;
        }
      }
    }
     .superiorityCol:nth-child(2){
       .block:nth-child(2){background-image: url('https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/home-img03.png');}
       .block:nth-child(3){background-image: url('https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/home-img04.png');}
       .block:nth-child(4){background-image: url('https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/home-img05.png');}
     }
     .superiorityCol:nth-child(3){
       .block:nth-child(2){background-image: url('https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/home-img06.png');}
       .block:nth-child(3){background-image: url('https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/home-img07.png');}
       .block:nth-child(4){background-image: url('https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/home-img08.png');}
     }
     .superiorityCol:nth-child(4){
       img{float: right;}
     }
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .superiority {
      .ant-col{width: 50%;}
      img{width: 100%;height: auto!important;}
      .block{
         display: flex;
         align-items: flex-start;
      }
  }
}
@media screen and (min-width: 1px) and (max-width: 767px) {
  .superiority{
      padding: 36px 0;
      img{width: 80%;height: auto!important;display: block;margin: 0 auto;}
      .superiorityRow .superiorityCol{
        margin-top: 0;
        height: auto;
      }
      .superiorityRow .superiorityCol:nth-child(4) img{display: none;}
  }
}


</style>

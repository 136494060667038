<template>
  <div class="service">
    <div class="fluid container">
      <div class="topTittle">
        <h2>中国知链的服务对象</h2>
        <!-- <p>important custom</p> -->
      </div>
      <a-row class="serviceRow">
        <a-col
        class="serviceCol"
          :xs="12"
          :md="12"
          :lg="12"
          :xl="6"
          v-for="(item,index) in list"
          :key="index"
        >
          <div 
          class="block" 
          :ref="refName+index"
          :class="{'animate__animated':refArray[refName+index],'animate__fadeInDown':refArray[refName+index]}">
            <div>
              <img :src="item.imgSrc" alt="">
              <h3>{{item.tittle}}</h3>
              <p>{{item.desc}}</p>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { scrollAnimate } from '../mixins'
export default {
  name: 'ServiceBlock',
  mixins: [scrollAnimate],
  data () {
    return {
      list:[
        {
          imgSrc: 'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/home-img10.png',
          tittle:'原创机构',
          desc:'报社、杂志社、原创作品网站，教育机构等能在知识产权领域有所应用原创的机构'
        },
        {
          imgSrc: 'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/home-img11.png',
          tittle:'原创作者',
          desc:'文章作者、原创个体等用户，根据需要定制各种不同种类的区块链行业应用'
        },
        {
          imgSrc: 'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/home-img12.png',
          tittle:'企业自媒体',
          desc:'微信、微博、头条号等媒体账号运营者，企业号运营者等自媒体建立成果确权及保护'
        },
        {
          imgSrc: 'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/home-img13.png',
          tittle:'政府单位',
          desc:'各级行政单位和事业单位等相关机构对信息的管理及监测'
        },
      ],
      refName:'ServiceBlock'
    }
  },
  methods: {
  }
}
</script>

<style lang='scss'>

.service {
  // background: #f7f8fb url('https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/serviceBg.png') no-repeat 50% 50%;
  background: #fff;
  background-size: 100% auto;
  padding: 72px 0;

  .topTittle {
    text-align: center;
    font-size: 20px;
    margin-bottom: 30px;
    h2 {
      font-size: 32px;
      margin-bottom: 4px;
      position: relative;
    }
    h2::after{
      content: '';
      width: 40px;
      height: 6px;
      background: #3377FE;
      position: absolute;
      left: 50%;
      margin-left: -20px;
      bottom: -30px;
    }
  }
  .ant-col{
    overflow: hidden;
  }
  .block {
    background: #fff;
    background-size: auto 80%;
    box-shadow: 0 0 25px 0px rgba(57, 96, 125, 0.06);
    margin-top: 20px;
    padding: 20px;
    height: 338px;
    text-align: center;
    img{
      width: 98px;
      height: 98px;
      margin: 20px;
    }
    h3 {
      margin-bottom: 20px;
      font-size: 16px;
      color: #112435;
    }
    p {
      font-size: 14px;
      line-height: 30px;
      color: #495c6f;
      padding: 0 10px;
    }
  }
  .block:hover{
    background: #F2F6FF;
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .service {
      .ant-col{width: 50%;}
      .block{
         display: flex;
         align-items: center;
         background-size: auto 80%;
      }
  }
}

@media screen and (min-width: 1px) and (max-width: 767px)  {
  .service {
    padding: 36px 0;
      .ant-col{padding: 5px;}
      .block{
         align-items: center;
         background-size: auto 80%;
         padding: 0 10px;
         height: 300px;
         img {margin: 10px 0;}
      }
  }
}
</style>
